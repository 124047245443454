import React from "react";
import classNames from "classnames";
import "./title.scss";


export const Title = props => {
    let Tag = props.tag;
    const classnames = classNames("e-title", {
        //size
        "e-title--size35": props.size35,
        "e-title--size20": props.size20,
        "e-title--size16": props.size16,

        //colors
        "e-title--white": props.white,

        "e-title--centered": props.centered,
        "e-title--normal": props.normal,
    });

    return (
        Tag?
            <Tag id={props.id} className={props.className ? props.className + ` ` + classnames : classnames}>
                {props.content}
            </Tag>
            :
            <p id={props.id} className={props.className ? props.className + ` ` + classnames : classnames}>
                {props.content}
            </p>
    );
};
