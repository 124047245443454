import React, { Component } from "react";
import Layout from "../../components/layout/layout.js";
import Module from "../../components/structure/Module/module.js";
import Wrapper from "../../components/structure/Wrapper/wrapper.js";
import {Title} from "../../components/elements/Title/title.js";
import RichText from "../../components/modules/RichText/richText";
import Seo from "../../components/seo/seo.js";
import List from "../../components/modules/List/list.js";

class Home extends Component {

    render() {
        const seo = this.props.pageContext.seo;
        const mainText = this.props.pageContext.mainText;
        const mainTitle = this.props.pageContext.mainTitle;
        const listTable = this.props.pageContext.listTable;

        return (
            <Layout>
                <Seo lang={seo.lang} title={seo.title} url={seo.url} description={seo.description}  robotsIndex={seo.robotsIndex} robotsFollow={seo.robotsFollow}/>
                <Module bgPr>
                    <Wrapper white className="pad24">
                        <Title tag="h1" size35 content={mainTitle} className="text-align--center marg-b16" />
                        <RichText content={mainText} />
                        <List col={3} colSm={1} data={listTable} />
                    </Wrapper>
                </Module>
            </Layout>
        );
    }
}

export default Home;